export default {
  base: 10,
  sticky: 15,
  activeControl: 20,
  hoveringToolbar: 22,
  scrollEffects: 25,
  menu: 30,
  modal: 40,
  dialog: 50,
}
